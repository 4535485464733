import { getSeatNumber } from '../../../helpers/getSeatNumber'
import { getSeatStatus } from '../../../helpers/getSeatStatus'
import { TSeatNumber } from '../../../types'

type TProps = {
  item: Element
  selectedSeats: TSeatNumber[]
  availableSeats: TSeatNumber[]
  extraPurchasedSeatsNumbers: string[]
}

export const setSeatStatus = ({
  item,
  selectedSeats,
  availableSeats,
  extraPurchasedSeatsNumbers,
}: TProps): void => {
  const seatNumber = getSeatNumber(item)
  const seatStatus = getSeatStatus({
    seatNumber,
    selectedSeats,
    availableSeats,
    extraPurchasedSeatsNumbers,
  })
  item.setAttribute('data-seat-status', seatStatus)
}
