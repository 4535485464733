import { TSeatStatus, TTooltipOptions, TSeatNumber } from '../types'

type TParams = {
  seatStatus: TSeatStatus
  tooltipOptions?: TTooltipOptions
  seatNumber: TSeatNumber
}

type TGetUnavailableLabel = {
  tooltipOptions: TTooltipOptions
  seatNumber: TSeatNumber
}

const getUnavailableLabel = ({ tooltipOptions, seatNumber }: TGetUnavailableLabel) => {
  const unavailableSeatReason = tooltipOptions.unavailableSeatsLabels[seatNumber]

  return unavailableSeatReason ?? tooltipOptions.seatUnavailable
}

export const getTooltipLabel = ({
  seatStatus,
  tooltipOptions,
  seatNumber,
}: TParams): string | null => {
  if (!tooltipOptions) return null

  if (seatStatus === TSeatStatus.unavailable) {
    return getUnavailableLabel({
      tooltipOptions,
      seatNumber,
    })
  }

  return tooltipOptions.availableSeatsLabels[seatNumber]
}
