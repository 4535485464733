import { OVERLAY_Z_INDEX } from '@frontend/ui/components/Overlay'
import Popover from '@frontend/ui/components/Popover'
import React from 'react'
import { useWithMedia } from '@frontend/ui/hooks/useWithMedia'
import { StyledSeat } from './styled'
import { getTooltipLabel } from '../../../helpers/getTooltipLabel'
import { TSeatNumber, TSeatStatus, TTooltipOptions } from '../../../types'

type TProps = {
  seatStatus: TSeatStatus
  onClick: () => void
  seatNumber: TSeatNumber
  tooltipOptions?: TTooltipOptions
}

export const Seat = ({ seatNumber, seatStatus, onClick, tooltipOptions }: TProps): JSX.Element => {
  const { isMobile } = useWithMedia()

  const tooltipLabel = getTooltipLabel({
    tooltipOptions,
    seatStatus,
    seatNumber,
  })

  const isPopoverHidden = isMobile || !tooltipLabel

  return (
    <Popover
      autoWidth
      content={tooltipLabel}
      isHidden={isPopoverHidden}
      placement="top"
      zIndex={OVERLAY_Z_INDEX + 1}
    >
      <StyledSeat data-seat-status={seatStatus} id={`Seat${seatNumber}`} onClick={onClick}>
        {seatNumber}
      </StyledSeat>
    </Popover>
  )
}
