/* eslint-disable no-param-reassign, prefer-destructuring, max-lines-per-function */
import React, { useLayoutEffect, useRef, useState } from 'react'
import Typography from '@frontend/ui/components/Typography'
import { applyTooltip } from './helpers/applyTooltip'
import { setSeatStatus } from './helpers/setSeatStatus'
import { getSeatsNodes } from './helpers/getSeatsNodes'
import { getSeatNumberNodes } from './helpers/getSeatNumberNodes'
import { TTooltipOptions, TSeatStatus, TNodesMap, TSeatNumber } from '../../types'
import {
  StyledMapWrap,
  StyledContainer,
  PopoverArrow,
  PopoverArrowContainer,
  StyledBorder,
  StyledMap,
} from './styled'
import { fixBrokenPathNode } from '../../helpers/fixBrokenPathNode'

type TProps = {
  selectedSeats: TSeatNumber[]
  extraPurchasedSeatsNumbers: TSeatNumber[]
  availableSeats: TSeatNumber[]
  onSelectSeat: (seatNumber: TSeatNumber) => void
  onCancelSelectedSeat: (seatNumber: TSeatNumber) => void
  onClickDisabledSeat?: (seatNumber: TSeatNumber) => void
  svgMap: string
  tooltipOptions?: TTooltipOptions
}

export const RzdMap = ({
  selectedSeats,
  extraPurchasedSeatsNumbers,
  onSelectSeat,
  onCancelSelectedSeat,
  onClickDisabledSeat,
  availableSeats,
  svgMap,
  tooltipOptions,
}: TProps): JSX.Element => {
  const [seatNumberNodes, setSeatNumberNodes] = useState<TNodesMap | null>(null)
  const [seatNodes, setSeatNodes] = useState<TNodesMap | null>(null)
  const [seatNumbers, setSeatNumbers] = useState<TSeatNumber[]>([])
  const [svgMapStyles, setSvgMapStyles] = useState('')

  const ref = useRef() as any

  /* eslint-disable-next-line max-statements, complexity */
  useLayoutEffect(() => {
    const parser = new DOMParser()
    const svgSource = svgMap.replace(/font-family.*?;/g, '')
    const dom = parser.parseFromString(svgSource, 'image/svg+xml')

    const title = dom.querySelector('title')
    if (title) {
      title.remove()
    }

    const styles = dom.querySelector('style')
    if (styles && styles?.innerHTML) {
      setSvgMapStyles(styles.innerHTML)
      styles.remove()
    }

    fixBrokenPathNode(dom)

    Array.from(dom.querySelectorAll('g'))
      .filter(item => item.id !== 'Seats')
      .forEach((item: any) => {
        item.style['pointer-events'] = 'none'
      })

    const seatNumberItems = getSeatNumberNodes(dom)
    const seatItems = getSeatsNodes(dom)

    setSeatNumberNodes(seatNumberItems)
    setSeatNodes(seatItems)
    if (seatNumberItems) {
      setSeatNumbers(Object.keys(seatNumberItems).map(item => item))
    }

    ref.current.innerHTML = ''
    ref.current.appendChild(dom.querySelector('svg'))
  }, [svgMap])

  useLayoutEffect(() => {
    if (seatNumberNodes && seatNodes) {
      seatNumbers.forEach(seatNumber => {
        const seatNumberNode = seatNumberNodes[seatNumber]
        const seatNode: any = seatNodes[seatNumber]

        setSeatStatus({
          item: seatNumberNode,
          selectedSeats,
          availableSeats,
          extraPurchasedSeatsNumbers,
        })

        if (!seatNode) return

        setSeatStatus({
          item: seatNode,
          selectedSeats,
          availableSeats,
          extraPurchasedSeatsNumbers,
        })

        seatNode.classList.add('seat')

        /* eslint-disable-next-line complexity */
        seatNode.onclick = () => {
          const seatStatus: TSeatStatus = seatNode.getAttribute('data-seat-status')

          if (seatStatus === TSeatStatus.available || seatStatus === TSeatStatus.extraPurchased) {
            onSelectSeat(seatNumber)
          }

          if (seatStatus === TSeatStatus.selected) {
            onCancelSelectedSeat(seatNumber)
          }

          if (seatStatus === TSeatStatus.unavailable && onClickDisabledSeat) {
            onClickDisabledSeat(seatNumber)
          }
        }

        applyTooltip({
          seatNode,
          seatNumber,
          tooltipOptions,
          seatNumberNode,
        })
      })
    }
  }, [
    availableSeats,
    onCancelSelectedSeat,
    onSelectSeat,
    selectedSeats,
    seatNumberNodes,
    seatNodes,
    tooltipOptions,
    seatNumbers,
    onClickDisabledSeat,
    extraPurchasedSeatsNumbers,
  ])

  return (
    <StyledContainer svgMapStyles={svgMapStyles}>
      <StyledMapWrap>
        <StyledMap ref={ref} />
        <StyledBorder />
      </StyledMapWrap>
      <div id="tooltip" role="tooltip">
        <Typography color="colorTextContrastNormal" id="tooltipLabel" variant="small">
          {' '}
        </Typography>
        <PopoverArrowContainer id="arrow">
          <PopoverArrow />
        </PopoverArrowContainer>
      </div>
    </StyledContainer>
  )
}
