import { TSeatStatus, TSeatNumber } from '../types'

type TProps = {
  selectedSeats: TSeatNumber[]
  availableSeats: TSeatNumber[]
  extraPurchasedSeatsNumbers: TSeatNumber[]
  seatNumber: TSeatNumber
}

export const getSeatStatus = ({
  selectedSeats,
  availableSeats,
  extraPurchasedSeatsNumbers,
  seatNumber,
}: TProps): TSeatStatus => {
  if (selectedSeats.includes(seatNumber)) {
    return TSeatStatus.selected
  }

  if (extraPurchasedSeatsNumbers.includes(seatNumber)) {
    return TSeatStatus.extraPurchased
  }

  if (!availableSeats.includes(seatNumber)) {
    return TSeatStatus.unavailable
  }

  return TSeatStatus.available
}
