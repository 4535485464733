import { getSeatNumber } from '../../../helpers/getSeatNumber'
import { TNodesMap } from '../../../types'
import { SEAT_NODE_REGEX } from '../../../consts'

export const getSeatsNodes = (dom: Document): TNodesMap | null => {
  const seatNodesMap: TNodesMap = {}

  const seatsContainer = dom.getElementById('Seats')

  if (!seatsContainer) return null

  const seatNodes = Array.from(seatsContainer.children).filter(item =>
    SEAT_NODE_REGEX.test(item.id)
  )

  seatNodes.forEach(node => {
    const seatNumber = getSeatNumber(node)
    seatNodesMap[seatNumber] = node
  })

  return seatNodesMap
}
