import React, { useEffect } from 'react'
import { TSeatNumber, TTooltipOptions } from './types'
import { CustomMap } from './components/CustomMap'
import { RzdMap } from './components/RzdMap'
import { StyledContainer } from './styled'
import { EMPTY_SVG_MAP_VALUE, RAILWAY_CAR_VIEW_WIDTH } from './consts'

type TProps = {
  selectedSeats: TSeatNumber[]
  extraPurchasedSeatsNumbers: TSeatNumber[]
  availableSeats: TSeatNumber[]
  onSelectSeat: (seatNumber: TSeatNumber) => void
  onCancelSelectedSeat: (seatNumber: TSeatNumber) => void
  onClickDisabledSeat?: (seatNumber: TSeatNumber) => void
  onGetMapWidth?: (width: number) => void
  svgMap: string
  tooltipOptions?: TTooltipOptions
}

export const RailwayCarView = ({
  selectedSeats,
  extraPurchasedSeatsNumbers,
  onSelectSeat,
  onCancelSelectedSeat,
  onClickDisabledSeat,
  availableSeats,
  svgMap,
  tooltipOptions,
  onGetMapWidth,
}: TProps): JSX.Element => {
  useEffect(() => {
    if (onGetMapWidth && svgMap !== EMPTY_SVG_MAP_VALUE) {
      onGetMapWidth(RAILWAY_CAR_VIEW_WIDTH)
    }
  }, [onGetMapWidth, svgMap])

  return (
    <StyledContainer>
      {svgMap === EMPTY_SVG_MAP_VALUE ? (
        <CustomMap
          availableSeats={availableSeats}
          extraPurchasedSeatsNumbers={extraPurchasedSeatsNumbers}
          selectedSeats={selectedSeats}
          tooltipOptions={tooltipOptions}
          onCancelSelectedSeat={onCancelSelectedSeat}
          onClickDisabledSeat={onClickDisabledSeat}
          onGetMapWidth={onGetMapWidth}
          onSelectSeat={onSelectSeat}
        />
      ) : (
        <RzdMap
          availableSeats={availableSeats}
          extraPurchasedSeatsNumbers={extraPurchasedSeatsNumbers}
          selectedSeats={selectedSeats}
          svgMap={svgMap}
          tooltipOptions={tooltipOptions}
          onCancelSelectedSeat={onCancelSelectedSeat}
          onClickDisabledSeat={onClickDisabledSeat}
          onSelectSeat={onSelectSeat}
        />
      )}
    </StyledContainer>
  )
}
