export type TSeatNumber = string

export type TTooltipOptions = {
  availableSeatsLabels: Record<TSeatNumber, string>
  unavailableSeatsLabels: Record<TSeatNumber, string>
  seatUnavailable: string
}

export enum TSeatStatus {
  unavailable = 'unavailable',
  selected = 'selected',
  available = 'available',
  extraPurchased = 'extraPurchased',
}

export type TNodesMap = Record<TSeatNumber, Element>
