import './style.css'
import React from 'react'
import ReactDOM from 'react-dom'
import UIThemeProvider from '@frontend/ui/components/ThemeProvider'
import styled from 'styled-components'
import Panel from './Panel'
import { RailwayCarView } from './RailwayCarView'

const MobilePhoneTheme = styled.div`
  background-color: ${({ theme }) => theme.color.colorBgPrimaryNormal};
`

function handleSelect(seat: string) {
  console.log('SELECTED', seat)

  try {
    // @ts-ignore
    webkit.messageHandlers.mapHandler.postMessage(JSON.stringify({ action: 'select', seat }))
  } catch (e) {
    console.error(e)
  }
  try {
    // @ts-ignore
    MapActionHandler.select(seat)
  } catch (e) {
    console.error(e)
  }
  return seat
}

function handleCancelSelectedSeat(seat: string) {
  console.log('CANCEL SELECT', seat)

  try {
    // @ts-ignore
    webkit.messageHandlers.mapHandler.postMessage(JSON.stringify({ action: 'unselect', seat }))
  } catch (e) {
    console.error(e)
  }
  try {
    // @ts-ignore
    MapActionHandler.cancelSelect(seat)
  } catch (e) {
    console.error(e)
  }
}

function handleGetMapWidth(width: number) {
  console.log('MAP WIDTH', width)
  const stringifiedWidth = JSON.stringify(width)

  try {
    // @ts-ignore
    webkit.messageHandlers.mapHandler.postMessage(JSON.stringify({ action: 'getMapWidth', width }))
  } catch (e) {
    console.error(e)
  }
  try {
    // @ts-ignore
    MapActionHandler.getMapWidth(stringifiedWidth)
  } catch (e) {
    console.error(e)
  }
  return stringifiedWidth
}

function handleClickDisabledSeat(seat: string) {
  console.log('CLICK DISABLED SEAT', seat)

  try {
    // @ts-ignore
    webkit.messageHandlers.mapHandler.postMessage(
      JSON.stringify({ action: 'clickDisabledSeat', seat })
    )
  } catch (e) {
    console.error(e)
  }
  try {
    // @ts-ignore
    MapActionHandler.clickDisabledSeat(seat)
  } catch (e) {
    console.error(e)
  }
  return seat
}

export function draw(seatsData: string): boolean {
  console.log('DRAW')
  console.log(seatsData)
  const { availableSeats, selectedSeats, extraPurchasedSeatsNumbers, svgMap, theme } =
    JSON.parse(seatsData)

  ReactDOM.render(
    <UIThemeProvider theme={theme}>
      <MobilePhoneTheme>
        <RailwayCarView
          availableSeats={availableSeats}
          extraPurchasedSeatsNumbers={extraPurchasedSeatsNumbers}
          selectedSeats={selectedSeats}
          svgMap={svgMap}
          onCancelSelectedSeat={handleCancelSelectedSeat}
          onClickDisabledSeat={handleClickDisabledSeat}
          onGetMapWidth={handleGetMapWidth}
          onSelectSeat={handleSelect}
        />
        {process.env.NODE_ENV === 'development' && <Panel />}
      </MobilePhoneTheme>
    </UIThemeProvider>,
    document.getElementById('root')
  )
  return true
}

function crash() {
  try {
    // @ts-ignore
    MapActionHandler.crash()
  } catch (e) {
    console.error(e)
  }
}

if (process.env.NODE_ENV === 'development') {
  ReactDOM.render(
    <UIThemeProvider>
      <Panel />
    </UIThemeProvider>,
    document.getElementById('root')
  )
}

// @ts-ignore
window.draw = draw

window.onerror = crash
