/*
 * https://kupibiletru.atlassian.net/browse/SITE-4532
 * https://kupibiletru.atlassian.net/browse/SITE-5040
 */
export const fixBrokenPathNode = (dom: Document): void => {
  const brokenElement = dom.getElementById('Seat133-134')

  if (brokenElement) {
    const xmlns = 'http://www.w3.org/2000/svg'

    const parentNode = brokenElement.parentNode as HTMLElement

    const seat133 = document.createElementNS(xmlns, 'path')
    seat133.setAttributeNS(null, 'id', 'Seat133')
    seat133.setAttributeNS(null, 'class', 'st2')
    seat133.setAttributeNS(
      null,
      'd',
      'M269.7,15.2L269.7,15.2v0.3c0,0.8,0.6,1.4,1.3,1.4c0,0,0,0,0.1,0h29.3v-2.2c0-0.5-0.4-0.9-0.9-0.9h-28.3 C270.4,13.8,269.8,14.4,269.7,15.2z M302.7,96V18.1c0-0.6-0.5-1.2-1.1-1.2c0,0-0.1,0-0.1,0h-6.6c0.9,2.4,1.6,4.9,2.2,7.4l0,0l0,0 c1.1,4.5,1.7,38.1,1.7,71.7 M298.8,96h-53.3V25.4c0-4.7,4.4-8.5,9.7-8.5h39.8c0.9,2.4,1.6,4.9,2.2,7.4 C298.2,28.9,298.8,62.4,298.8,96z'
    )

    const seat134 = document.createElementNS(xmlns, 'path')
    seat134.setAttributeNS(null, 'id', 'Seat134')
    seat134.setAttributeNS(null, 'class', 'st2')
    seat134.setAttributeNS(
      null,
      'd',
      'M269.7,176.6L269.7,176.6v0.3c0,0.8,0.6,1.4,1.3,1.4c0,0,0,0,0.1,0h28.5c0.5,0,0.9-0.4,0.9-0.9v-2.3h-29.3 C270.4,175.2,269.8,175.8,269.7,176.6z M298.8,96c0,33.6-0.6,67.2-1.7,71.7c-0.6,2.5-1.3,5-2.2,7.4h6.6c0.6,0,1.2-0.5,1.2-1.1 c0,0,0-0.1,0-0.1V96 M298.8,96c0,33.6-0.5,67.2-1.7,71.7c-0.6,2.5-1.3,5-2.2,7.5h-39.8c-5.3,0-9.7-3.8-9.7-8.5V96H298.8z'
    )
    parentNode.insertBefore(seat133, brokenElement)
    parentNode.insertBefore(seat134, brokenElement)

    parentNode.removeChild(brokenElement)
  }
}
