import React, { useEffect, useRef } from 'react'
import { useWithMedia } from '@frontend/ui/hooks/useWithMedia'
import { SEAT_WIDTH, GAP_BETWEEN_SEATS } from './consts'
import { RAILWAY_CAR_VIEW_WIDTH } from '../../consts'
import { getSeatStatus } from '../../helpers/getSeatStatus'
import { StyledCustomMapWrapper } from './styled'
import { TSeatNumber, TSeatStatus, TTooltipOptions } from '../../types'
import { Seat } from './Seat'
import { getSeatNumberInteger } from '../../helpers/getSeatNumberInteger'

type TProps = {
  selectedSeats: TSeatNumber[]
  extraPurchasedSeatsNumbers: TSeatNumber[]
  availableSeats: TSeatNumber[]
  onSelectSeat: (seatNumber: TSeatNumber) => void
  onCancelSelectedSeat: (seatNumber: TSeatNumber) => void
  onClickDisabledSeat?: (seatNumber: TSeatNumber) => void
  onGetMapWidth?: (width: number) => void
  tooltipOptions?: TTooltipOptions
}

type THandleSeatClick = {
  seatStatus: TSeatStatus
  seatNumber: TSeatNumber
}

const MAX_SEATS_IN_ROW = 26

export const CustomMap = ({
  selectedSeats,
  extraPurchasedSeatsNumbers,
  availableSeats,
  onSelectSeat,
  onCancelSelectedSeat,
  onClickDisabledSeat,
  tooltipOptions,
  onGetMapWidth,
}: TProps): JSX.Element => {
  const ref = useRef<any>()
  const { isMobile } = useWithMedia()

  const sortedAvailableSeats = [...availableSeats].sort(
    (a, b) => getSeatNumberInteger(a) - getSeatNumberInteger(b)
  )
  const seatsCount = sortedAvailableSeats.length

  const isOneRowSeats = seatsCount <= MAX_SEATS_IN_ROW

  /* eslint-disable-next-line complexity */
  const handleSeatClick = ({ seatStatus, seatNumber }: THandleSeatClick) => {
    if (seatStatus === TSeatStatus.available || seatStatus === TSeatStatus.extraPurchased) {
      onSelectSeat(seatNumber)
    }

    if (seatStatus === TSeatStatus.selected) {
      onCancelSelectedSeat(seatNumber)
    }

    if (seatStatus === TSeatStatus.unavailable && onClickDisabledSeat) {
      onClickDisabledSeat(seatNumber)
    }
  }

  useEffect(() => {
    if (!onGetMapWidth) return

    if (isOneRowSeats) {
      const totalSeatsWidth = seatsCount * SEAT_WIDTH
      const totalGapBetweenSeats = (seatsCount - 1) * GAP_BETWEEN_SEATS

      onGetMapWidth(totalSeatsWidth + totalGapBetweenSeats)
      return
    }

    onGetMapWidth(RAILWAY_CAR_VIEW_WIDTH)
  }, [onGetMapWidth, availableSeats.length, isMobile, isOneRowSeats, seatsCount])

  return (
    <StyledCustomMapWrapper ref={ref} isOneRowSeats={isOneRowSeats}>
      {sortedAvailableSeats.map(seatNumber => {
        const seatStatus = getSeatStatus({
          availableSeats,
          selectedSeats,
          extraPurchasedSeatsNumbers,
          seatNumber,
        })

        return (
          <Seat
            key={seatNumber}
            seatNumber={seatNumber}
            seatStatus={seatStatus}
            tooltipOptions={tooltipOptions}
            onClick={() => handleSeatClick({ seatStatus, seatNumber })}
          />
        )
      })}
    </StyledCustomMapWrapper>
  )
}
