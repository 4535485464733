import styled from 'styled-components'
import { borderRadiusSmall } from '@frontend/ui/utils/borderRadius'
import { queries } from '@frontend/ui/utils/media-queries'
import { SEAT_WIDTH } from '../consts'
import { TSeatStatus } from '../../../types'

export const StyledSeat = styled.div`
  padding: 8px;
  width: ${SEAT_WIDTH}px;
  height: ${SEAT_WIDTH}px;
  border: 2px solid ${({ theme }) => theme.color.colorBorderPrimaryNormal};
  ${borderRadiusSmall.all}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &[data-seat-status=${TSeatStatus.available}] {
    color: ${({ theme }) => theme.color.colorTextPrimaryNormal};
    @media not ${queries.isMobile} {
      &:hover {
        background-color: ${({ theme }) => theme.color.colorBgPrimaryHover};
      }
    }
  }

  &[data-seat-status=${TSeatStatus.selected}] {
    background-color: ${({ theme }) => theme.color.colorBgAccentNormal};
    border-color: ${({ theme }) => theme.color.colorBgAccentNormal};
    color: ${({ theme }) => theme.color.colorTextContrastNormal};

    @media not ${queries.isMobile} {
      &:hover {
        background-color: ${({ theme }) => theme.color.colorBgAccentHover};
        border-color: ${({ theme }) => theme.color.colorBgAccentHover};
      }
    }
  }

  &[data-seat-status=${TSeatStatus.extraPurchased}] {
    background-color: ${({ theme }) => theme.color.colorBgStatusPrimaryNormal};
    border-color: ${({ theme }) => theme.color.colorBgStatusPrimaryNormal};
    color: ${({ theme }) => theme.color.colorTextSecondaryNormal};

    @media not ${queries.isMobile} {
      &:hover {
        background-color: ${({ theme }) => theme.color.colorBgStatusPrimaryHover};
        border-color: ${({ theme }) => theme.color.colorBgStatusPrimaryHover};
      }
    }
  }

  &[data-seat-status=${TSeatStatus.unavailable}] {
    cursor: default;
    background-color: ${({ theme }) => theme.color.colorBgPrimaryDisable};
    color: ${({ theme }) => theme.color.colorTextPrimaryDisable};
  }
`
