import styled from 'styled-components'
import { borderRadiusSmall } from '@frontend/ui/utils/borderRadius'
import { CONTAINER_ANIMATION_KEYFRAMES } from '@frontend/ui/components/Popover/styled'
import { queries } from '@frontend/ui/utils/media-queries'
import { OVERLAY_Z_INDEX } from '@frontend/ui/components/Overlay'
import popoverArrowIcon from './assets/popoverArrow.svg'
import { RAILWAY_CAR_VIEW_WIDTH, RAILWAY_CAR_VIEW_HEIGHT } from '../../consts'
import { TSeatStatus } from '../../types'

export const PopoverArrowContainer = styled.div`
  animation: 0.15s ease-out forwards ${CONTAINER_ANIMATION_KEYFRAMES};
`

export const PopoverArrow = styled.div`
  width: 20px;
  height: 8px;
  background-image: url(${popoverArrowIcon});
`

export const StyledContainer = styled.div<{ svgMapStyles: string }>`
  ${({ svgMapStyles }) => svgMapStyles};

  #tooltip {
    animation: 0.15s ease-out forwards ${CONTAINER_ANIMATION_KEYFRAMES};
    background: ${({ theme }) => theme.color.colorBgContrastNormal};
    color: ${({ theme }) => theme.color.colorTextContrastNormal};
    padding: 8px 12px;
    display: none;
    ${borderRadiusSmall.all}
    pointer-events: none;
    z-index: ${OVERLAY_Z_INDEX + 1};
  }

  #tooltip[data-show] {
    display: block;

    @media ${queries.isMobile} {
      display: none;
    }
  }

  #tooltip[data-popper-placement='top'] {
    ${PopoverArrow} {
      transform: rotate(180deg);
    }

    ${PopoverArrowContainer} {
      bottom: -7px;
    }
  }
  #tooltip[data-popper-placement='bottom'] {
    ${PopoverArrowContainer} {
      top: -7px;
    }
  }
`

export const StyledBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px #7a7b7c;
`

export const StyledMap = styled.div`
  display: flex;
  width: ${RAILWAY_CAR_VIEW_WIDTH}px;
  max-height: ${RAILWAY_CAR_VIEW_HEIGHT}px;

  overflow: hidden;
  border-radius: 12px;

  .seat {
    cursor: pointer;
    user-select: none;
  }

  svg {
    width: 100%;
  }

  [data-seat-status=${TSeatStatus.selected}] {
    fill: ${({ theme }) => theme.color.colorBgAccentNormal};
    stroke: ${({ theme }) => theme.color.colorBorderPrimaryNormal};

    @media not ${queries.isMobile} {
      :hover {
        fill: ${({ theme }) => theme.color.colorBgAccentHover};
        stroke: ${({ theme }) => theme.color.colorBorderPrimaryNormal};
      }
    }
  }

  [data-seat-status=${TSeatStatus.available}] {
    stroke: ${({ theme }) => theme.color.colorBorderPrimaryNormal};

    @media not ${queries.isMobile} {
      :hover {
        fill: ${({ theme }) => theme.color.colorBgPrimaryHover};
      }
    }
  }

  [data-seat-status=${TSeatStatus.extraPurchased}] {
    fill: ${({ theme }) => theme.color.colorBgStatusPrimaryNormal};
    stroke: ${({ theme }) => theme.color.colorBorderPrimaryNormal};

    @media not ${queries.isMobile} {
      :hover {
        fill: ${({ theme }) => theme.color.colorBgStatusPrimaryHover};
        stroke: ${({ theme }) => theme.color.colorBorderPrimaryNormal};
      }
    }
  }

  [data-seat-status=${TSeatStatus.unavailable}] {
    cursor: default;
    fill: ${({ theme }) => theme.color.colorBgPrimaryDisable};
    stroke: ${({ theme }) => theme.color.colorBorderPrimaryNormal};
  }

  // Numbers
  text[data-seat-status=${TSeatStatus.selected}] {
    fill: ${({ theme }) => theme.color.colorTextContrastNormal};
  }

  text[data-seat-status=${TSeatStatus.extraPurchased}] {
    fill: ${({ theme }) => theme.color.colorTextSecondaryNormal};
  }

  text[data-seat-status=${TSeatStatus.available}] {
    fill: ${({ theme }) => theme.color.colorTextSecondaryNormal};
  }

  text[data-seat-status=${TSeatStatus.unavailable}] {
    fill: ${({ theme }) => theme.color.colorTextPrimaryDisable};
  }

  .seatNumberHover[data-seat-status=${TSeatStatus.selected}] {
    fill: ${({ theme }) => theme.color.colorTextContrastNormal};
  }
`

export const StyledMapWrap = styled.div`
  position: relative;
  width: ${RAILWAY_CAR_VIEW_WIDTH}px;
`
