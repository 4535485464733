import { getSeatNumber } from '../../../helpers/getSeatNumber'
import { TNodesMap } from '../../../types'

export const getSeatNumberNodes = (dom: Document): TNodesMap | null => {
  const seatNumberNodesMap: TNodesMap = {}

  const seatNumbersContainer = dom.getElementById('Numbers')

  if (!seatNumbersContainer) return null

  Array.from(seatNumbersContainer.children).forEach(node => {
    const seatNumber = getSeatNumber(node)
    seatNumberNodesMap[seatNumber] = node
  })

  return seatNumberNodesMap
}
