// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// import a330300 from './airplanes/a330-300.json'
// import boeing777300ER from './airplanes/777-300ER.json'
// import a321 from './airplanes/a321.json'

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const Button = styled.button`
  padding: 8px 16px;
`

// const airplanes = {
//   a330300,
//   boeing777300ER,
//   a321,
// } as const

const Panel = (): JSX.Element => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light')
  const [airplane, setAirplane] = useState<keyof typeof airplanes>('a330300')

  useEffect(() => {
    const data = {
      // ...mapData(airplanes[airplane]),
      theme,
    }

    window.draw(JSON.stringify(data))
  }, [airplane, theme])

  const onThemeChange = () => {
    if (theme === 'light') {
      setTheme('dark')
    }
    if (theme === 'dark') {
      setTheme('light')
    }
  }

  return (
    <Container>
      <Button onClick={() => setAirplane('a330300')}>A330-300</Button>
      <Button onClick={() => setAirplane('boeing777300ER')}>Boeing 777-300ER</Button>
      <Button onClick={() => setAirplane('a321')}>A321</Button>
      <Button onClick={onThemeChange}>Change theme</Button>
    </Container>
  )
}

export default Panel
