import styled, { css } from 'styled-components'
import { queries } from '@frontend/ui/utils/media-queries'
import { GAP_BETWEEN_SEATS } from './consts'
import { RAILWAY_CAR_VIEW_WIDTH } from '../../consts'

export const StyledCustomMapWrapper = styled.div<{ isOneRowSeats: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${GAP_BETWEEN_SEATS}px;
  width: ${RAILWAY_CAR_VIEW_WIDTH}px;

  @media ${queries.isMobile} {
    ${({ isOneRowSeats }) =>
      isOneRowSeats &&
      css`
        width: auto;
      `}
  }
`
