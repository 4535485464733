/* eslint-disable no-param-reassign */
import { createPopper } from '@popperjs/core'
import { TSeatNumber, TSeatStatus, TTooltipOptions } from '../../../types'
import { getTooltipLabel } from '../../../helpers/getTooltipLabel'

type TProps = {
  tooltipOptions?: TTooltipOptions
  seatNode: any
  seatNumber: TSeatNumber
  seatNumberNode: Element
}

let popperInstance: any

export const applyTooltip = ({
  tooltipOptions,
  seatNode,
  seatNumber,
  seatNumberNode,
}: TProps): void => {
  if (!tooltipOptions) return

  const tooltip = document.getElementById('tooltip') as HTMLElement
  const tooltipLabelNode = document.getElementById('tooltipLabel') as HTMLElement
  const arrow = document.getElementById('arrow') as HTMLElement

  const seatStatus: TSeatStatus = seatNode.getAttribute('data-seat-status')

  const tooltipLabel = getTooltipLabel({
    tooltipOptions,
    seatNumber,
    seatStatus,
  })

  if (popperInstance) {
    popperInstance.update()
  }

  seatNode.onmouseover = () => {
    if (!tooltipLabel) return

    tooltipLabelNode.innerText = tooltipLabel

    tooltip.setAttribute('data-show', '')
    popperInstance = createPopper(seatNumberNode, tooltip, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrow,
          },
        },
      ],
    })
  }

  seatNode.onmouseleave = () => {
    tooltip.removeAttribute('data-show')
    if (popperInstance) {
      popperInstance.destroy()
    }
  }
}
